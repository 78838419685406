/* IN Style Variables */
@import '../colors';

$primary: #182157;
$secondary: #c3922e;
$light: #e6e6e6;
$fade-white: rgba(249, 246, 246, 0.7);

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg: #e6e6e6;
$body-color: #222;

//Process Page Styling
$process-page-themes: (
  'claim': #161044,
  'holder': #161044
) !default;

$btn-pop-bg: $primary;

//Process Page Styling
$claim-header-bg: #161044;
$holder-header-bg: #161044;
$navbar-bg: #161044;
$header-bg: #161044;

$dropdown-bg: #161044;
$dropdown-hover-bg: none;
$dropdown-divider-bg: white;

$dropdown-link-color: #eee;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: lighten($primary, 5%);

$mobile-navbar-bg: lighten(#161044, 10%);
$static-page-header-color: $primary;
$static-page-subtitle-color: $secondary;

/* Image Size & Source Variables */
$banner-image-url: url('/images/banner.jpg');
$body-background-image-url: url('/images/bg-texture.png');

$claim-header-logo-url: url('/images/Process_Claims_Logo.png');
$claim-header-logo-width: 213px;
$claim-header-logo-height: 85px;

$holder-header-logo-url: url('/images/HR_Process_Logo.png');
$holder-header-logo-width: 200px;
$holder-header-logo-height: 55px;

$home-logo-url: url('/images/Static_Logo.png');
$home-logo-width: 450px;
$home-logo-height: 108px;
$home-logo-resize-value-map: (
  width: 110px,
  height: $home-logo-height,
  background-image: $home-logo-url
);

$jumbotron-bg: $fade-white;

$treasurer-logo-url: url('/images/treasurer.png');
$treasurer-logo-width: 86px;
$treasurer-logo-height: 118px;
$treasurer-logo-resize-value-map: (
  width: $treasurer-logo-width,
  height: $treasurer-logo-height,
  background-image: $treasurer-logo-url
);

$footer-logo-url: url('/images/IN_seal.png');
$footer-logo-width: 150px;
$footer-logo-height: 150px;
$footer-bg: #161044;

$claimform-image-url: url('/images/in_claim_form_img.png');

$icon-email-url: none;
$icon-facebook-url: url('/images/icon_facebook.png');
$icon-instagram-url: url('/images/icon_instagram.png');
$icon-twitter-url: url('/images/icon_twitter.png');
$icon-youtube-url: url('/images/icon_youtube.png');
$icon-linkedin-url: none;

$social-icon-width: 25px;
$social-icon-height: 25px;
